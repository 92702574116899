<template>
    <div class="pc" v-wechat-title="$route.meta.title">
      <navigation :msg="msg"></navigation>
      <img src="../../assets/pc.png" alt="" />
      <p>请至PC端官网使用此功能</p>
    </div>
</template>
<script>
export default {
  data(){
    return{
      msg:"请至PC端"
    }
  }
}
</script>
<style scoped>
.pc {
  height: 100%;
  background-color: #ffffff;
}
.pc img {
  margin-top: 3.61rem;
  margin-left: 50%;
  transform:translateX(-50%) ;
  width: 3.24rem;
  height: 3.16rem;
}
.pc p {
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  margin-top: 0.57rem;
  margin-left: 50%;
  transform: translateX(-50%);
  text-align: center;
}
</style>